<template>
    <div class="site__centered">
        <div class="my-applications">
          <div class="my-applications__header">
            <h2 class="my-applications__title mobile-hidden">My Applications</h2>
            <!-- <div class="message__success" v-if="showThankYou && applicationProgramName">
              {{ this.prepareMessage(this.applicationProgramName) }}
            </div> -->
          </div>
            <div class="my-applications__row">
                <SideNav/>

                <div class="my-applications__row__col-9 my-applications__row__col-sm-12">
                    <div class="my-applications__tab-column">

                      <ApplicationItem
                        v-for="application in applicationsList"
                        :key="application.id"
                        :application="application"
                      />

                      <div>
                        <span v-if="!applicationsList.length">There are no active application on your account</span>
                        <button
                          v-b-modal.add-family-member
                          class="btn btn--color-dark family__add-member"
                          @click="$router.push('programs')"
                        >
                          Register Now
                        </button> 
                      </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApplicationItem from './ApplicationItem'
import SideNav from '@/components/site/SideNav.vue'

import { prepareApplicationSubmissionMessage } from '@core/mixins/prepareApplicationSubmissionMessage'
import store from '@/store'

export default {
  components: {
    ApplicationItem,
    SideNav,
  },
  mixins: [prepareApplicationSubmissionMessage],
  data: () => ({
    isLoading: false,
    showThankYou: false,
    applicationProgramName: '',
  }),
  async created() {
    store.commit('SET_LOADING_STATUS', true)

    await store.dispatch('applications/fetchApplicationsList')
      .finally(() => {
      })
      
      store.commit('SET_LOADING_STATUS', false)

  },
  computed: {
    applicationsList () {
      return store.getters['applications/getApplicationsList']
    },
  },
  methods: {
    async getApplicationName(id) {
      this.isLoading = true
      await store.dispatch('applications/fetchApplicationById', id)
        .then(response => {
          this.applicationProgramName = response.program
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if(from.name === "application-create"){
        if (to.query.id) {
          vm.getApplicationName(to.query.id)
          vm.showThankYou = true
        }
      }
    })
  }
}
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .message__success {
    font-size: 16px;
    line-height: 20px;
    padding: 20px;
    white-space: pre-wrap;
  }
}
</style>
